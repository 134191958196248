@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inknut+Antiqua:wght@300&family=Kalam&family=Mooli&family=Roboto:ital,wght@1,500&display=swap");

* {
  color: #222;
}

.nametag {
  margin-top: 12px;

  text-align: center;
  font-size: 64pt;
  font-family: "Bebas Neue", "Impact", sans-serif;
}

h1 {
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  font-family: "Bebas Neue", "Impact", sans-serif;
  font-size: 2em;
}

nav.sticky {
  z-index: 1;
  height: 24px;
  top: 0;
  position: sticky;

  background-color: #222;
  color: #eee;

  text-align: center;
}

.navLink {
  z-index: 2;
  color: #eee;
  font-weight: bold;
}

.navLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

section {
  max-width: 1032px;
  margin: 0px auto;

  padding: 24px 0px;
}

.project {
  position: relative;

  height: 300px;
  width: 1000px;

  display: flex;
  justify-content: space-between;

  background-position-y: 0px;
  filter: grayscale(80%);
  transition: background-position-y 10s, filter 0.5s;
}

.project:hover {
  background-position-y: -100px;
  filter: grayscale(0%);
  transition: background-position-y 10s, filter 1s;
  cursor: pointer;
}

.oddProject {
  flex-direction: row;
  text-align: left;
}

.evenProject {
  flex-direction: row-reverse;
  text-align: right;
}

.projectTitle {
  flex-grow: 1;
  padding: 16px 32px;
  align-self: flex-end;

  font-size: 32pt;
  font-family: "Bebas Neue", "Impact", sans-serif;

  background-color: rgba(0, 0, 0, 0.8);

  color: #eee;
  text-shadow: 2px 2px #222;
}

.project:hover .projectTitle {
  text-decoration: underline;
}

.closed {
  width: 32px;
  transition: width 0.5s;
  height: 300px;
  overflow: hidden;

  background-color: white;
}

.open {
  width: 600px;
  transition: width 0.5s;
  height: 300px;
  overflow: hidden;

  background-color: white;
}

.evenProject * .projectInfoBox {
  float: right;
  align-items: flex-end;
}

.projectInfoBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 400px;
  height: 252px;
  margin: 16px 48px;
  font-weight: bold;
}

.projectInfoBox p {
  flex: 1;
}

.preamble {
  width: 50%;
  margin: 24px auto;
  font-weight: bold;
}

.tagBar {
  display: flex;
}

.tag {
  width: 200px;
  height: 24px;
  margin-right: 8px;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.react {
  background-color: #149eca;
  color: white;
}

.gm8 {
  background-color: #f40000;
  color: white;
}

.gitHub {
  background-color: #222;
  color: white;
}

.gitHub:hover {
  text-decoration: underline;
}

.noSrc {
  background-color: #aaa;
  color: #555;
}

.tagText {
  color: inherit;
}

.sectionDivider {
  width: 100%;
  min-height: 24px;
  padding-left: 24px;
  margin: 8px auto;

  background-color: #222;

  color: #666;
  font-size: small;
  text-align: bottom;
}

.sectionDivider:has(+ footer) {
  margin-bottom: 0px;
}

.contact {
  display: flex;
  justify-content: space-evenly;
}

.contactLink {
  width: 200px;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
}

.contactLink:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #555;
}

.contactLink i {
  margin-right: 8px;
}

/* Layout degrades below 1000px width, so switch to a 'mobile' version below that */
@media screen and (max-width: 1000px) {
  .closed {
    width: 0px;
  }

  .project {
    width: 100%;
  }

  .contact {
    flex-direction: column;
    gap: 8px;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .contactLink {
    width: 100%;
  }

  .preamble {
    width: 80%;
    margin: 24px auto;
  }

  .sectionDivider {
    box-sizing: border-box;
  }

  .project {
    height: 300px;
  }

  .projectTitle {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
  }

  .open {
    height: 300px;
    width: 80%;
    z-index: 9;
  }

  .projectInfoBox {
    width: calc(100%-32px);
    height: 200px;
    margin: 8px 16px;
  }

  .tagBar {
    flex-direction: column;
    gap: 8px;
  }
}

@media screen and (max-width: 500px) {
  .open {
    width: 100%;
  }
}

/* In case of extremely narrow screens, use smaller text and relative container widths (text will look stange in motion) */
@media screen and (max-width: 400px) {
  .projectInfoBox {
    width: calc(100% - 48px);
    font-size: small;
  }
}
